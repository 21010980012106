/* eslint-disable import/prefer-default-export */
import RoomItemAvatar from 'features/chat/LeftSidebar/ChatRooms/RoomItemAvatar';
import { notification } from 'antd';
import Push from 'push.js';
import router from 'next/router';
import Image from 'global/Image';
import { assetPrefix, PROJECT_FOLDER_PATH } from 'constants/app';

import Avatar from '@deprecated-ui/common/Avatar';
import { handleErrorAvatar } from 'utils/general';
import { CHAT_PUSH_NOTIFICATION_INDEX } from 'features/chat/constant';
import { removeFormat } from 'features/chat/utils';
import { parseNotificationMessage } from './utils';

const prefix = `${assetPrefix}/images`;

interface iConfiguration {
  body: string;
  timeout: number;
  onClick: () => void;
  tag?: string;
  icon?: string;
}

export function createPush(
  title = 'Stockbit',
  msg = '',
  { tag, timeout, icon },
  callBack,
) {
  if (!Push) throw new Error('push js not exists');

  const configuration: iConfiguration = {
    body: msg,
    timeout: 4000,
    onClick: callBack,
  };
  if (tag) configuration.tag = tag;
  if (timeout) configuration.timeout = timeout;
  if (icon) configuration.icon = icon;
  Push.create(title, configuration);
}

const showChatNotification = ({
  title,
  description,
  isDeactivate,
  name,
  shortName,
  type,
  avatar,
  id,
  key = `open${Date.now()}`,
  isPersonalChat,
  isInvitedChat,
  onClose,
}) => {
  const handleClick = () => {
    notification.close(key);
    const url = new URL(`${window.location.origin}`);

    switch (type) {
      case 'ROOM_TYPE_GROUP':
        url.pathname = `${PROJECT_FOLDER_PATH}/group-chat/${id}`;
        break;
      case 'ROOM_TYPE_BROADCAST':
        url.pathname = `${PROJECT_FOLDER_PATH}/broadcast-chat/${id}`;
        break;
      case 'ROOM_TYPE_PERSONAL':
      default:
        url.pathname = `${PROJECT_FOLDER_PATH}/chat/${id}`;
        break;
    }

    window.open(url, '_blank');
  };

  if (!router.asPath.includes(id)) {
    // browser push notification
    if (document.hidden) {
      const optional = {
        tag: `notif_chatv2_${id}`,
        timeout: 4000,
        icon: `${prefix}/stockbitlogo-notification.png`,
      };
      const pushNotifAction = () => {
        const baseURL = `${window.location.origin}${PROJECT_FOLDER_PATH}`;
        const pathName = isPersonalChat ? 'chat' : 'group-chat';
        // accepted room chat
        let urlPushNotif = `${baseURL}/${pathName}/${id}?source=${CHAT_PUSH_NOTIFICATION_INDEX}`;

        // requested room chat
        if (isInvitedChat) {
          urlPushNotif = `${baseURL}/${pathName}/${id}?type=request&source=${CHAT_PUSH_NOTIFICATION_INDEX}`;
        }

        window.focus();
        window.open(urlPushNotif, '_blank');
      };
      return createPush(title, description, optional, function () {
        pushNotifAction();
        if (this && typeof this.close === 'function') this.close();
      });
    }
    notification.open({
      key,
      className: 'float-chat-notification',
      placement: 'bottomLeft',
      message: title,
      description: removeFormat(description),
      duration: 7,
      onClick: handleClick,
      onClose,
      icon: (
        <RoomItemAvatar
          isDeactive={isDeactivate}
          name={name}
          shortName={shortName}
          type={type}
          src={avatar}
        />
      ),
    });
  }
  return null;
};

const showGeneralNotification = (notifData, onClose) => {
  const baseURL = `${window.location.origin}${PROJECT_FOLDER_PATH}`;

  let actionUrl = '';
  let showNotif = true;
  let message = 'Stockbit'; // default message
  let icon = '';
  let textValue = '';
  const maskedHtml = notifData?.data?.message_mask;

  if (showNotif && notifData?.data?.avatar) {
    icon = notifData.data.avatar;
  }

  if (maskedHtml) {
    // get the username
    textValue = Object.keys(maskedHtml).reduce(
      (_, curr) => (maskedHtml ? `${maskedHtml[curr].text}` : ''),
      '',
    );
  }

  switch (parseInt(notifData.type, 10)) {
    case 3:
      // Go to user profile
      actionUrl = `/${notifData.data.link_to.identifier}`;
      break;
    case 4: // reports
      // Go to company page
      actionUrl = `/symbol/${notifData.data.link_to.identifier}`;
      break;
    case 2: // mention
    case 5:
    case 6: // Comment Post
    case 7: // activity
    case 9: // Save Post
    case 12:
      // Go to post page
      actionUrl = `/post/${notifData.data.link_to.identifier}`;
      break;
    // case '22':
    case 21:
    case 23:
      actionUrl = `/tip/${notifData.data.link_to.identifier}`;
      break;
    case 42: // Community (Join/Left)
      showNotif = true;
      if (textValue) {
        actionUrl = `${baseURL}/community?search=${textValue}`;
      } else {
        actionUrl = `${baseURL}/community`;
      }
      message = 'External Community';
      icon = '/images/stockbit-bubble-white.png';
      break;
    case 44: // Day Trade
    case 45: // Sharia Stock Delisted
      actionUrl = '/securities/portfolio';
      break;
    default:
      actionUrl = '';
      break;
  }

  if (showNotif) {
    const messageText = notifData.data.message;
    const parsedText = parseNotificationMessage(messageText, maskedHtml);

    notification.open({
      key: `notification-${notifData.id}`,
      className: 'float-general-notification',
      placement: 'bottomLeft',
      message,
      description: parsedText,
      duration: 7,
      onClose,
      onClick: () => window.open(actionUrl, '_blank'),
      icon: (
        <Avatar size="medium">
          <Image
            src={icon}
            onError={(e) => handleErrorAvatar(e, 'Stockbit')}
            style={{ width: '48px', height: '48px' }}
          />
        </Avatar>
      ),
    });
  }
};

const showAlertNotification = (notifData, onClose) => {
  notification.open({
    key: `notification-${notifData?.alert_id}`,
    className: 'float-alert-notification',
    placement: 'bottomLeft',
    message: 'Stockbit',
    description: notifData?.msg,
    duration: 7,
    onClose,
    onClick: () => notification.close(`notification-${notifData?.alert_id}`),
    icon: (
      <div style={{ width: 71, height: 71 }}>
        <Image
          src="/icon/stockbit-logo-notif.png"
          onError={(e) => handleErrorAvatar(e, 'Stockbit')}
          style={{ objectFit: 'contain' }}
        />
      </div>
    ),
  });
};

export { showChatNotification, showGeneralNotification, showAlertNotification };
